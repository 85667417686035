import { data } from "./experience-data.js";

function ExperienceMap() {
  return (
    <div>
      {data.map((item, index) => {
        return (
          <div key={index} className="resumeCard">
            <div className="experience-flex">
              <div className="experience-left">
                <b>
                  <p className="highlight">{item.company}</p>
                  <p>{item.position}</p>
                </b>
              </div>
              <div className="experience-right">
                <b>
                  <p style={{ textAlign: "right", marginBottom: "0" }}>
                    {item.location}
                  </p>
                  <p style={{ textAlign: "right" }}>{item.date}</p>
                </b>
              </div>
            </div>

            <p>{item.description}</p>
          </div>
        );
      })}
    </div>
  );
}

export default ExperienceMap;
