import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import "./../styles/navbar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faFacebookF, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";


const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #FFE5EC;
  // transform: ${({ open }) => (open ? "translateY(0)" : "translateY(-150%)")};
  //   height: 80vh;
  width: 100%;
  text-align: left;
  padding: 60px 20px 20px;
  position: absolute;
  padding-top: 80px;
  left: 0;
  transition: transform 0.3s ease-in-out;
  box-sizing: border-box;
  z-index: 3;

  hr {
    background-color: #FB6F92;
    height: 2px;
    border: none;
    width: 100%;
    // padding: 0 22px;
  }

  @media (max-width: 576px) {
    width: 100%;
  }
`;

const BlackBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transform: ${({ open }) => (open ? "translateY(0)" : "translateY(-150%)")};
  transition: transform 0.3s ease-in-out;
`;

const Menu = ({ myRef, open, setOpen }) => {
  return (
    <BlackBackground open={open}>
      <StyledMenu ref={myRef}>
        <div className="mobile-item">
          <Link to="about" onClick={() => setOpen(false)}>
            <span>About</span>
          </Link>
        </div>

        <hr />

        <div className="mobile-item">
          <Link to="resume" onClick={() => setOpen(false)}>
            <span>Resume</span>
          </Link>
        </div>

        <hr />

        <div className="mobile-item">
          <Link to="portfolio" onClick={() => setOpen(false)}>
            <span>Portfolio</span>
          </Link>
        </div>

        <hr />

        <div className="contacts">
        <a
            href="https://www.instagram.com/taliashap/"
            target="_blank"
            rel="noreferrer"
            className="icons"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100084322122101"
            target="_blank"
            rel="noreferrer"
            className="icons"
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a
            href="https://www.linkedin.com/in/talia-shapiro-60aab4251/"
            target="_blank"
            rel="noreferrer"
            className="icons"
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
          <a
            href="mailto: taliashap@aol.com"
            target="_blank"
            rel="noreferrer"
            className="icons"
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </div>
      </StyledMenu>
    </BlackBackground>
  );
};

const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  position: absolute;
  float: left;
  margin: 0;
  left: 10px;
  top: 17px;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: #FB6F92;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger id="burger-button" open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

function BurgerMenu(props) {
  const buttonId = document.getElementById("burger-button");
  const [open, setOpen] = React.useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false), buttonId);
  return (
    <div>
      <Burger open={open} setOpen={setOpen} />
      <Menu
        myRef={node}
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
}

const useOnClickOutside = (ref, handler, id) => {
  useEffect(() => {
    const listener = (event) => {
      if (
        ref.current == event.target ||
        ref.current.contains(event.target) ||
        document.getElementById("burger-button") == event.target ||
        document.getElementById("burger-button") == event.target.parentElement
      ) {
        return;
      } else {
        handler(event);
      }
    };
    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};

export default BurgerMenu;
