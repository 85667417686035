import React, { useEffect } from 'react';
import '../styles/navbar.scss';
import { Link } from "react-router-dom";
import BurgerMenu from './BurgerMenu.js';

function NavBar() {
  
  useEffect(() => {
    const resizeHeaderOnScroll = () => {
      const distanceY = window.pageYOffset || document.documentElement.scrollTop,
        shrinkOn = 50,
        headerEl = document.getElementById("navbar");
  
      if (distanceY > shrinkOn) {
        headerEl.classList.add("smaller");
      } else {
        headerEl.classList.remove("smaller");
      }
    }
    // Update the document title using the browser API
    window.addEventListener("scroll", resizeHeaderOnScroll);
    // return () => window.removeEventListener("scroll", onScroll);
  });
    
  
  
  return (
    <div>
    <header id='navbar'>
      <ul id='nav'>
        <li>
          <Link to='/'>
            <img id='logo' src='talia-logo.png' alt="Main Logo"/>
          </Link>
        </li>
        <li>
          <Link to='/about' className="regLink">
            About
          </Link>
        </li>
        <li>
          <Link className="regLink" to='/resume'>
            Resume
          </Link>
        </li>
        <li>
          <Link className="regLink" to='/portfolio'>
            Portfolio
          </Link>
        </li>
        
      </ul>
    </header>


    <header id='navbarMobile'>
      <BurgerMenu />
      <ul id='nav'>
        <li>
          <Link id="mobileLogo" to='/'>
            <img id='logoMobile' src='talia-logo.png' alt="Mobile Logo"/>
          </Link>
        </li>

        
      </ul>
    </header>

    </div>
    
  );
}

export default NavBar;
