import Tab from "./tab.js";
import Tabs from "./tabs.js";
import "../styles/resume.scss";
import { motion } from "framer-motion";
import EducationGrid from "./resume/education-grid.js";
import ExperienceMap from "./resume/experience-map.js";

function Resume() {
  document.title = "Talia Shapiro | Resume";
  return (
    <Tabs>
      <Tab title="Education">
        <motion.div
          initial={{ y: 80 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          <div id="education">
            <div id="eduImage">
              <img alt="UMD Logo" src="UMD.png"></img>
            </div>
          </div>
          <div>
            <EducationGrid />
          </div>
          <div id="courses">
            <p id="coursesHeader">Courses Taken</p>
            <ul id="courseList">
              <li>Evidence-Based Practices in Athletic Health Care</li>
              <li>The Psychology of Sport and Exercise</li>
              <li>Motor Development</li>
              <li>Anatomy and Physiology I &amp; II</li>
              <li>Honor Seminar</li>
            </ul>
          </div>
        </motion.div>
      </Tab>

      <Tab title="Professional Experience">
          <div id="experience">
            <ExperienceMap />
          </div>
      </Tab>
    </Tabs>
  );
}

export default Resume;
