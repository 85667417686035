function EducationGrid(){

    const eduKeys= [
        "Location",
        "Attended From" ,
        "Major",
        "Degree",
        "GPA",
        "President",
        "President"
]

    const eduVals = ["College Park, MD", "09/2022 – Current","Kinesiology","Bachelor of Arts","3.7","Kinesiology Student Organization","Bio-Medical Club"]
    
    return (
        <ul className='grid-container'>
            {eduKeys.map((item,index) => {
                return(           
                <li key={index} className="grid-item">
                    <span>{item}</span>
                    <hr></hr>
                    {eduVals[index]}
                </li>
                )
            })}
        </ul>
    )
}

export default EducationGrid;
