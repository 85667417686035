import "../styles/portfolio.scss";
// import PortfolioGrid from './portfolio/portfolio-grid';
import { motion } from "framer-motion";

function Portfolio() {
  return (
    <motion.div
      initial={{ y: 80 }}
      whileInView={{ y: 0 }}
      transition={{ duration: 1 }}
      viewport={{ once: true }}
    >
      <div
        id="portfolio"
        className="animate__animated animate__fadeInDown animate__slow"
      >
        <h2>Portfolio</h2>
        {/* <PortfolioGrid /> */}
      </div>
    </motion.div>
  );
}

export default Portfolio;
