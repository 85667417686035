export const data = [
  {
    company: 'Chavaya Religious School',
    location: 'College Park, MD',
    date: '08/2023 – Present',
    position: 'Teacher',
    description:
    'Assist students in all their day to day learning activities.'
  },
  {
    company: 'Olami JHealth',
    location: 'College Park, MD',
    date: '08/2023 – Present',
    position: 'Fellowship',
    description:
      'Attended seminar with speakers, clinics, and students from all over the country to learn about the medical field.',
  },
  {
    company: 'Magen David Adom',
    location: 'Tel Aviv, Israel',
    date: '10/2021 – 06/2022',
    position: 'Volunteer',
    description:
      'Served as a volunteer on an ambulance.',
  },
  {
    company: 'Self',
    location: 'Boca Raton, FL',
    date: '08/2019 – 06/2021',
    position: 'Private Tutor',
    description:
      'Tutored local community family in English and Hebrew studies.',
  },
  {
    company: 'YHS Yearbook',
    location: 'Boca Raton, FL',
    date: '08/2019 – 06/2021',
    position: 'Writing Editor',
    description:
      'Oversaw the production and content of the school’s yearbook and was responsible for managing the team of writers, as well as editing and proofreading.',
  },
];
