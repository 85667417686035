import './App.scss';
import NavBar from './components/navbar.js';
import Footer from './components/footer.js';
// import Home from './components/home.js';
import Resume from './components/resume.js';
import About from './components/about.js';
import Portfolio from './components/portfolio.js';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div id='page-container'>
        <NavBar />

        <div className='main'>
          <Switch>
            <Route path='/resume'>
              <Resume />
            </Route>

            <Route path='/about'>
              <About />
            </Route>

            <Route path='/portfolio'>
              <Portfolio />
            </Route>

            <Route path='/'>
              <Redirect to="/about" />
            </Route>
          </Switch>
        </div>
        <div id='footerTest'>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
