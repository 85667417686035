import React, { useCallback } from "react";
import { motion } from "framer-motion";

function TabTitle(props) {
  var index = props.index;
  var title = props.title;
  var setSelectedTab = props.setSelectedTab;
  const onClick = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  return (
    <motion.div
      initial={{ y: 80 }}
      whileInView={{ y: 0 }}
      transition={{ duration: 1 }}
      viewport={{ once: true }}
    >
      <li className="tab-item">
        <button className={"tab-button " + props.class} onClick={onClick}>
          {title}
        </button>
      </li>
    </motion.div>
  );
}

export default TabTitle;
