import React from "react";
import "../styles/about.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBirthdayCake,
  faSignature,
  faMapPin,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

function About() {
  document.title = "Talia Shapiro | About"

  return (
    <motion.div
      initial={{ y: 80 }}
      whileInView={{ y: 0 }}
      transition={{ duration: 1 }}
      viewport={{ once: true }}
      id="about"
    >
      <h2>About Talia</h2>

      <div className="aboutFlex">
        <div className="aboutPicture">
          <img src="talia-park.jpeg" alt="Headshot" />
          <div className="aboutInfo">
            <span className="aboutInfoSpan">
              <span style={{ marginRight: "10px" }}>
                <FontAwesomeIcon icon={faSignature} />
              </span>
              Talia Shapiro
            </span>
            <span className="aboutInfoSpan">
              <span style={{ marginRight: "10px" }}>
                <FontAwesomeIcon
                  icon={faBirthdayCake}
                />
              </span>
              August 19th, 2003
            </span>
            <span className="aboutInfoSpan">
              <span style={{ marginRight: "10px" }}>
                <FontAwesomeIcon icon={faMapPin} />
              </span>
              Boca Raton, FL
            </span>
            <a href="mailto: taliashap@aol.com">
              <span className="aboutInfoSpan">
                <span style={{ marginRight: "10px" }}>
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                taliashap@aol.com
              </span>
            </a>
          </div>
        </div>
        <div className="aboutContent">
          <p>
            My name is Talia Shapiro and I’m a dedicated junior at the
            University of Maryland, pursuing a degree in Kinesiology with a
            passion for becoming a physician assistant. My academic journey
            reflects my commitment to the healthcare field, showcasing a strong
            foundation in kinesiology and a clear aspiration for a future in
            medical care. Beyond my studies, I have a well-rounded life with a
            diverse set of interests. I maintain a healthy and active
            lifestyle, while also enjoying shopping, relaxing at the beach, and
            spending time with friends and family.
          </p>
        </div>
      </div>
    </motion.div>
  );
}

export default About;
